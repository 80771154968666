<template>
  <el-row :gutter="20" class="components-common-applyDemo">
    <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" class="left">
      <div class="left-wrapper">
        <el-row class="head">申请体验</el-row>
        <el-row class="detail"
          >为了给您提供更优质的SaaS级服务体验，帮助您更好了解和使用航动科技系列产品，在您递交体验申请后，将有技术专家为您提供一对一专属服务。</el-row
        >
        <el-row :gutter="20">
          <el-col
            :xs="8"
            :sm="8"
            :md="8"
            :lg="8"
            :xl="8"
            v-for="(item, index) in introList"
            :key="index"
          >
            <el-image :src="item.img"></el-image>
            <el-row class="tip">{{ item.title }}</el-row>
          </el-col>
        </el-row>
        <el-row class="footer">
          <span>特别提示：</span>服务人员通常会在2个工作日内与您联系。
        </el-row>
      </div>
    </el-col>
    <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" class="right">
      <div class="right-wrapper">
        <h2 class="title">数智赋能客户微生态运营</h2>
        <el-form
          :model="infoForm"
          :rules="infoRule"
          ref="infoForm"
          :hide-required-asterisk="true"
        >
          <el-form-item prop="company" class="required-item">
            <div class="item-left">
              <img :src="imgList.company" alt="" />
            </div>
            <el-input
              v-model.trim="infoForm.company"
              autocomplete="off"
              placeholder="请输入公司名称"
            ></el-input>
          </el-form-item>
          <el-form-item prop="name" class="required-item">
            <div class="item-left"><img :src="imgList.name" alt="" /></div>

            <el-input
              v-model.trim="infoForm.name"
              autocomplete="off"
              placeholder="请输入您的姓名"
            ></el-input>
          </el-form-item>
          <el-form-item prop="phone" class="required-item">
            <div class="item-left"><img :src="imgList.phone" alt="" /></div>

            <el-input
              v-model.trim="infoForm.phone"
              autocomplete="off"
              placeholder="请输入您的联系电话"
            ></el-input>
          </el-form-item>
          <el-form-item prop="vCode" class="required-item">
            <div class="item-left">
              <img :src="imgList.vCode" alt="" />
            </div>
            <el-input
              v-model.trim="infoForm.vCode"
              autocomplete="off"
              maxlength="6"
              placeholder="请输入验证码"
            ></el-input>
            <img class="yanzheng" :src="imgList.yanzheng" alt="" />
          </el-form-item>
          <el-form-item prop="other">
            <div class="item-left">
              <img :src="imgList.other" alt="" />
            </div>
            <el-input
              v-model.trim="infoForm.other"
              autocomplete="off"
              placeholder="请输入其他备注信息"
            ></el-input>
          </el-form-item>
          <el-button @click="onSubmitClick">立即体验</el-button>
        </el-form>
      </div>
    </el-col>
  </el-row>
</template>
<script>
export default {
  name: "components-common-applyDemo",
  data() {
    return {
      imgList: {
        company: require("@/assets/images/tk-rt-ico1.png"),
        name: require("@/assets/images/tk-rt-ico2.png"),
        phone: require("@/assets/images/tk-rt-ico3.png"),
        vCode: require("@/assets/images/tk-rt-ico4.png"),
        other: require("@/assets/images/tk-rt-ico5.png"),
        yanzheng: require("@/assets/images/yanzheng.jpg"),
      },
      infoRule: {
        company: [
          { required: true, trigger: "blur", message: "请输入公司名称" },
        ],
        name: [{ required: true, trigger: "blur", message: "请输入您的姓名" }],
        phone: [
          { required: true, trigger: "blur", message: "请输入您的联系电话" },
          {
            validator: (rule, value, callback) => {
              let reg = /^1[34578]\d{9}$/;
              if (reg.test(value)) {
                callback();
              } else {
                callback(new Error("手机号不合法"));
              }
            },
            trigger: "blur",
          },
        ],
        vCode: [
          { required: true, trigger: "blur", message: "请输入验证码" },
          {
            validator: (rule, value, callback) => {
              let str = "868zv";
              if (str == value.toLowerCase()) {
                callback();
              } else {
                callback(new Error("验证码不正确"));
              }
            },
            trigger: "blur",
          },
        ],
      },
      infoForm: {
        company: "",
        name: "",
        phone: "",
        vCode: "",
        other: "",
      },
      introList: [
        {
          title: "产品解说",
          img: require("@/assets/images/tk-lt-img1.png"),
        },
        {
          title: "方案配置",
          img: require("@/assets/images/tk-lt-img2.png"),
        },
        {
          title: "产品体验",
          img: require("@/assets/images/tk-lt-img3.png"),
        },
      ],
    };
  },
  methods: {
    onSubmitClick() {
      this.$refs.infoForm.validate((valid) => {
        if (valid) {
          let { company, phone, name, other } = this.infoForm;
          console.log("提交", company, phone, name, other);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.components-common-applyDemo {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;

  .left {
    background: url(../../assets/images/tanBox-lt-bgs.jpg) no-repeat center
      center/cover;
    color: #fff;
    flex-shrink: 1;
  }
  .right {
    background: #fff;
    flex-shrink: 1;
    background: url(../../assets/images/tanBox-rt-bg.jpg) no-repeat right
      bottom/cover;
  }
  .left-wrapper {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 125px;
    max-width: 520px;
    margin: 0 auto;
    .head {
      margin-bottom: 45px;
      font-size: 48px;
      font-weight: bold;
      text-align: center;
      width: 100%;
    }
    .detail {
      font-size: 16px;
      line-height: 36px;
      text-align: center;
      color: #fff;
      max-width: 490px;
      margin-bottom: 50px;
    }
    .tip {
      text-align: center;
      font-size: 18px;
      color: #fff;
    }
    .footer {
      text-align: center;
      margin-top: 60px;
      font-size: 14px;
      color: #fff;
      opacity: 0.5;
      span {
        color: #ffe461;
      }
    }
  }
  .right-wrapper {
    width: 100%;
    margin: 0 auto;
    max-width: 600px;
    .title {
      color: #333;
      font-size: 44px;
      text-align: center;
      margin-bottom: 50px;

      margin-top: 125px;
    }
  }
  /deep/ .el-form-item__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    display: flex;
    padding: 0 40px;
    height: 70px;
    background-color: #f2f8ff;
    .item-left {
      position: relative;
      padding-right: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &::after {
        content: "";
        width: 1px;
        height: 16px;
        background-color: #d2d2d2;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .required-item {
    .item-left {
      &::before {
        content: "*";
        position: absolute;
        right: -10px;
        top: 50%;
        transform: translateY(-50%);
        color: red;
      }
    }
  }
  .el-button {
    padding: 0;
    display: block;
    width: 245px;
    height: 70px;
    text-align: center;
    line-height: 70px;
    font-size: 24px;
    color: #fff;
    background-color: #1f63d2;
    border-radius: 70px;
    border: 0;
    margin: auto;
  }
  /deep/ .el-input {
    .el-input__inner {
      border: 0;
      background: transparent;
      font-size: 16px;
    }
  }
  /deep/ .el-form-item__error {
    left: 80px;
  }
  .yanzheng {
    height: 70px;
  }
}
@media screen and (max-width: 1600px) {
  .components-common-applyDemo {
    .left-wrapper {
      .head {
        margin-bottom: 45px;
        font-size: 40px;
      }
      .detail {
        font-size: 14px;
        line-height: 30px;
        margin-bottom: 50px;
      }
      .tip,
      .footer {
        font-size: 16px;
      }
      .footer {
        margin-top: 50px;
      }
    }
    .right-wrapper {
      .title {
        margin-bottom: 40px;
      }
    }
    .el-button {
      height: 60px;
      line-height: 60px;
      width: 225px;
      font-size: 20px;
    }
    /deep/ .el-form-item {
      margin-bottom: 0;
    }
    /deep/ .el-form-item__content {
      height: 60px;
      margin-bottom: 15px;
    }
    .yanzheng {
      height: 60px;
    }
  }
}
@media screen and (max-width: 1500px) {
  .components-common-applyDemo {
    .left-wrapper {
      .head {
        margin-bottom: 25px;
        font-size: 30px;
      }
      .detail {
        font-size: 13px;
        line-height: 26px;
        margin-bottom: 35px;
      }
      .tip {
        font-size: 14px;
      }
      .footer {
        margin-top: 35px;
      }
    }
    .right-wrapper {
      .title {
        font-size: 30px;
        margin-bottom: 30px;
      }
    }
    .el-button {
      height: 52px;
      line-height: 52px;
    }
    /deep/ .el-form-item__content {
      height: 52px;
      margin-bottom: 20px;
    }
    /deep/ .el-input {
      .el-input__inner {
        font-size: 14px;
      }
    }
    .yanzheng {
      height: 52px;
    }
  }
}
@media screen and (max-width: 1200px) {
  .components-common-applyDemo {
    .left-wrapper {
      width: 80%;
    }
  }
}

/* iPad竖板显屏 - portrait指定输出设备中的页面可见区域高度大于或等于宽度 */
@media screen and (max-width: 992px) and (orientation: portrait) {
  .components-common-applyDemo {
    display: inline-block;
    .left-wrapper {
      padding: 35px 0;
      .head {
        font-size: 28px;
      }
    }
    .right-wrapper {
      .title {
        font-size: 20px;
        margin-top: 35px;
      }
    }
  }
}
@media screen and (max-width: 680px) and (orientation: portrait) {
  .components-common-applyDemo {
    overflow-x: hidden;
    .left-wrapper {
      width: 100%;

      .head {
        font-size: 20px;
        margin-bottom: 15px;
      }
      .detail {
        font-size: 12px;
        line-height: 22px;
        margin-bottom: 15px;
      }
      .tip,
      .footer {
        font-size: 12px;
      }
      .footer {
        margin-top: 20px;
      }
    }
    .right-wrapper {
      .title {
        font-size: 20px;
        margin-bottom: 15px;
      }
    }
    .el-button {
      width: 170px;
      height: 46px;
      line-height: 46px;
      font-size: 16px;
    }
    /deep/ .el-form-item__content {
      padding: 0 20px;
      height: 46px;
      .item-left {
        padding-right: 10px;
        img {
          width: 16px;
        }
      }
    }
    /deep/ .el-input {
      .el-input__inner {
        font-size: 13px;
      }
    }
    .yanzheng {
      width: 96px;
      height: 46px;
    }
  }
}
</style>
